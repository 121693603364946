<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12" md="12">
        <v-card :elevation="1" color="#26c6da2b">
          <v-card-text class="py-1">
            <v-row dense>
              <v-col cols="12" md="2">
                <v-combobox placeholder="Salary Month" dense outlined hide-details :items="months" item-text="name"
                  item-value="id" class="custom-form" v-model="selectedMonth" @focus="getMonths"></v-combobox>
              </v-col>
              <v-col cols="12" md="2">
                <v-combobox label="Department" dense outlined hide-details
                  :items="$store.getters['department/departments']" v-model="department" item-text="name"
                  item-value="id" :loading="$store.getters['department/loadingDepartments']"
                  @focus="$store.dispatch('department/getDepartments')" class="custom-form"></v-combobox>
              </v-col>

              <v-col cols="12" md="2">
                <v-combobox label="Designation" dense outlined hide-details
                  :items="$store.getters['designation/designations']" v-model="designation" item-text="name"
                  item-value="id" :loading="$store.getters['designation/loadingDesignations']"
                  @focus="$store.dispatch('designation/getDesignations')" class="custom-form"></v-combobox>
              </v-col>

              <v-col cols="12" md="2">
                <v-combobox label="Shift" dense outlined hide-details :items="$store.getters['shift/shifts']"
                  v-model="shift" item-text="name" item-value="id" :loading="$store.getters['shift/loadingShifts']"
                  @focus="$store.dispatch('shift/getShifts')" class="custom-form"></v-combobox>
              </v-col>

              <v-col cols="12" md="2">
                <v-combobox label="Employee" dense outlined hide-details v-model="employee"
                  :items="$store.getters['employee/employees']" :loading="$store.getters['employee/loadingEmployees']"
                  item-text="display_name" item-value="id" class="mb-1 custom-form" @focus="getEmployees"></v-combobox>
              </v-col>

              <v-col cols="6" md="1">
                <v-btn elevation="1" small color="primary" dark style="margin-top: -2px" :loading="loading"
                  @click="getReports">
                  Search
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="records.length > 0">
      <v-col cols="12">
        <v-btn color="primary" class="white--text" small dense @click="print">
          <v-icon left small dense> mdi-printer </v-icon>
          Print
        </v-btn>
      </v-col>
      <v-col cols="12" class="py-0">
        <v-toolbar dense color="white" :elevation="1" height="30px">
          <v-spacer></v-spacer>
          <v-toolbar-title class="subtitle-2">TDS Report of {{ selectedMonth.name }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
      </v-col>
      <v-col cols="12" id="reportContent">
        <v-simple-table class="record_table">
          <tr class="record_heading_tr">
            <th>SL.</th>
            <th> Name </th>
            <th> Designation </th>
            <th> Department </th>
            <th> TDS Amount </th>
          </tr>
          <tr v-for="(salary, sl) in records" :key="sl">
            <td>{{ sl + 1 }}</td>
            <td style="text-align: left;">
              {{ salary.employee_name }}
            </td>
            <td>{{ salary.designation_name }}</td>
            <td>{{ salary.department_name }}</td>
            <td style="text-align: right;">{{ salary.tax_amount }}</td>
          </tr>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import utility from "../../mixins/utility.mixin";
import moment, { months } from "moment";
export default {
  mixins: [utility],
  data() {
    return {
      date_from: this.toISOLocal().substr(0, 10),
      date_to: this.toISOLocal().substr(0, 10),
      department: null,
      designation: null,
      shift: null,
      employee: null,
      salary: null,
      records: [

      ],
      months: [],
      selectedMonth: null,
      loading: false,
    };
  },
  filters: {
    dateFormat(dt, format) {
      return dt == '' || dt == null ? '' : moment(dt).format(format);
    }
  },
  async created() {
    // await this.getSalaries();
  },
  methods: {
    async getMonths() {
      this.months = await this.$store.dispatch('month/getSalaryMonths', {
        apiParams: {},
        options: {
          returnData: true
        }
      });
    },
    getEmployees() {
      this.employee = null;
      this.$store.dispatch("employee/getEmployees", {
        apiParams: {
          departmentId: this.department?.id ?? null,
          designationId: this.designation?.id ?? null,
          shiftId: this.shift?.id ?? null,
        },
      });
    },
    async getReports() {
      this.records = [];
      this.loading = true;

      if (this.selectedMonth == null) {
        this.loading = false;
        this.$store.dispatch('snackbar/error', 'Please select month');
        return;
      }

      this.records = await this.$store.dispatch("salary/getSalaries", {
        apiParams: {
          departmentId: this.department?.id ?? null,
          designationId: this.designation?.id ?? null,
          shiftId: this.shift?.id ?? null,
          employeeId: this.employee?.id ?? null,
          month_id: this.selectedMonth?.id ?? null,
          detail: 'yes'
        },
        options: {
          returnData: true,
        },
      }
      );

      this.loading = false;
    },
    async print() {
      let title = `TDS Report of ${this.selectedMonth.name}`;

      let company_profile = this.$store.getters["company/company"];

      // Get HTML to print from element
      const prtHtml = document.getElementById("reportContent").innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      // const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
      const WinPrint = window.open(
        "",
        "PRINT",
        `height=${screen.height}, width=${screen.width}`
      );
      WinPrint.document.write(`<!DOCTYPE html>
        <html>
        <head>
            ${stylesHtml}
        </head>
        <body>
            <div data-app="true" class="v-application v-application--is-ltr theme--light" id="app">
                <div class="container container--fluid">
                    <div class="row row--dense">
                        <div class="col-2 px-2">
                            <img style="width: 100%; height: 50px;" src="${this.$store.state.host +
        company_profile.logo
        }">
                        </div>
                        <div class="col-10 px-2 text-center">
                            <span class="font-weight-black">
                                ${company_profile.name}
                            </span>
                            <br>
                            <span>${company_profile.address}</span>
                            <br>
                            <span>
                                Email:${company_profile.email
        }, Contact:${company_profile.phone}
                            </span>
                        </div>
                        <div class="col-12 px-2 text-center">
                            <p class="font-weight-black custom_header">
                                ${title}
                            </p>
                        </div>
                        <div class="col col-12 px-2">
                            ${prtHtml}
                        </div>
                    </div>
                </div>
            </div>
        </body>
        </html>`);

      WinPrint.document.head.innerHTML += `
        <style>
            .v-data-table__wrapper{
                overflow: unset;
            }
            .record_heading_tr th{
                text-align: center !important;
            }
            .custom_header{
                background-color: #0000001f;
                border-radius: 4px;
                margin-bottom: 5px !important;
            }
            tr:hover{
                background: unset !important;
            }
            .record_heading_tr:hover{
                background: #4caf7773 !important;
            }
            @media print {
                table.record_table { page-break-after:auto }
                table.record_table tr    { page-break-inside:avoid; page-break-after:auto }
                table.record_table td    { page-break-inside:avoid; page-break-after:auto }
                table.record_table thead { display:table-header-group }
                table.record_table tfoot { display:table-footer-group }
            }
        </style>`;

      WinPrint.focus();
      WinPrint.document.close();
      await new Promise((resolve) => setTimeout(resolve, 2000));
      WinPrint.print();
      WinPrint.close();
    },
  },
};
</script>

<style>
.record_table table,
.record_table th,
.record_table td {
  border: 1px solid #dee2e6;
  border-collapse: collapse;
  padding: 0 !important;
}

.record_table th,
.record_table td {
  padding: 2px !important;
  font-size: 11px !important;
  text-align: center;
  height: 0 !important;
}

.record_heading_tr {
  background: #4caf7773;
}

.record_heading_tr th {
  text-align: center !important;
}
</style>